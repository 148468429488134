import React, { useEffect } from 'react'

const Modal = ({
  children,
  size,
  setShowModal,
}: {
  className?: String
  children: React.ReactElement
  size?: string
  setShowModal?: any
}) => {
  const sizeClass = size ? size : 'w-4/5 md:w-2/5'

  const handleShowModal = () => {
    if (setShowModal) {
      setShowModal(false)
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Escape') {
      handleShowModal()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-90 bg-blackOpacity">
      <div
        className={`p-4 bg-white ${sizeClass} relative overflow-scroll max-h-4/5`}
      >
        {children}
      </div>
    </div>
  )
}

export default Modal
