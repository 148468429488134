const ModuleSelector = ({
  models,
  addModelCallback,
  // eslint-disable-next-line
  cancelCallback
}: {
  models: { [key: string]: string };
  addModelCallback: (modelKey: string) => void
  cancelCallback: () => void;
}) => {
  return (
    <div>
      <>
        <div className="flex flex-wrap mt-2">
          {Object.keys(models).map((modelKey) => (
            <div
              key={modelKey}
              onClick={() => addModelCallback(modelKey)}
              className="px-2 py-1 mb-2 mr-2 text-xs bg-white border cursor-pointer border-grayLight hover:font-medium"
            >
              {models[modelKey]}
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

export default ModuleSelector;
