import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Container from "../components/Container";
import { GlobalContext } from "../contexts/GlobalContext";
import fetchAPI from "../lib/fetchAPI";

const Sitemap = () => {
  const { getToken, logout, getLiteral } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  useEffect(() => {
    const fn = async () => {
      setLoading(true);
      const { valid, data } = await fetchAPI({
        url: "sitemap/preview",
        method: "POST",
        body: {},
        token: getToken(),
      });
      setLoading(false);
      if (!valid) logout();
      setData(data);
    };

    fn();
  }, []);

  return (
    <div>
      <Helmet></Helmet>
      <div>
        <Container>
          <div className="flex items-center justify-between mb-8">
            <h1 className="font-medium text-xxl">{getLiteral("sitemap")}</h1>
          </div>
          <div>
            {!loading && data && (
              <>
                {Object.keys(data?.sitemap).map((key) => (
                  <div key={key} className="mb-12">
                    <h2 className="text-xl">{key}</h2>
                    <table className="w-full text-left">
                      <thead className="relative w-full text-xs font-normal text-left uppercase bg-white border-b cursor-pointer border-grayLight">
                        <tr>
                          <th width="50%" className="p-2 py-3 font-normal">
                            {getLiteral("url")}
                          </th>
                          <th width="30%" className="p-2 py-3 font-normal"></th>
                          <th
                            width="20%"
                            className="p-2 py-3 font-normal text-right"
                          >
                            {getLiteral("actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.sitemap[key].map((item) => (
                          <tr
                            key={item.id}
                            className="relative bg-white border-b border-grayLight hover:bg-grayLighter"
                          >
                            <td
                              width="50%"
                              className="p-2 py-3 font-mono text-xs"
                            >
                              {item?.paths.map((path) => (
                                <div key={path.url}>
                                  <span>{path.url}</span>
                                </div>
                              ))}
                            </td>
                            <td width="30%" className="p-2 py-3 text-xs">
                              {item?.title?.en ||
                                item?.title?.es ||
                                item?.title?.ca ||
                                item?.title}
                            </td>
                            <td width="20%" className="p-2 py-3 text-right">
                              <Link
                                className="mr-4 cursor-pointer hover:text-primary"
                                to={{
                                  pathname: `/admin/edit/${key}/${item.id}`,
                                }}
                              >
                                {getLiteral("edit")}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Sitemap;
