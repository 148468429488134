import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../contexts/GlobalContext";
import upload from "../../lib/upload";
import useImageLoaded from "../../hooks/useImageLoaded";
import dayjs from "dayjs";
import { AppConfig, PttFile } from "../../types";

const itemsPerPage = 72;

enum SortBy {
  createdAt = "createdAt",
  originalName = "originalName",
  type = "type",
}

const sortByLabels = {
  createdAt: "Created at",
  originalName: "Name",
  type: "Type",
};

type Props = {
  value: any;
  onChange: (file: PttFile) => void;
  onClose: () => void;
  type: "image" | "file";
};

type Data = {
  items: PttFile[];
  numberOfPages: number;
  total: number;
};

const MediaViewer = ({ onChange, onClose, type }: Props) => {
  const navigate = useNavigate();

  const { appConfig, getToken, logout } = useContext(GlobalContext);

  const [data, setData] = useState<Data>();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState(0);
  const [previewFile, setPreviewFile] = useState<PttFile>();
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.createdAt);

  const selectImages = async () => {
    var input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = type === "image" ? "image/*" : "*";

    input.onchange = (e) => {
      if (e?.target) {
        const target = e.target as HTMLInputElement;
        if (target?.files) {
          uploadFiles(Array.from(target?.files));
        }
      }
    };

    input.click();
  };

  const uploadFiles = async (files: File[]) => {
    setImagesToUpload(files.length);

    if (files.length === 0) return;

    const fileInfo = await upload(
      type,
      getToken() as string,
      appConfig as AppConfig,
      files[0]
    );
    if (!fileInfo) {
      console.error("Error uploading file", files[0]);
    }

    const nextFiles = [];
    for (let i = 1; i < files.length; i++) {
      nextFiles.push(files[i]);
    }
    if (Array.from(nextFiles).length > 0) {
      uploadFiles(nextFiles);
    } else {
      setImagesToUpload(0);
      if (page === 0) {
        getData();
      } else {
        setPage(0);
      }
    }
  };

  const deleteImage = async (image: PttFile) => {
    if (
      !window.confirm(
        "Are you sure you want to delete this image? Make sure to remove the image from all the places where you used it"
      )
    )
      return;

    try {
      await fetch(`${process.env.REACT_APP_API_URL}media/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ _id: image._id }),
      });
    } catch (e) {
      console.error("Cannot delete image", e);
    }
    setPreviewFile(undefined);
    await getData();
  };

  const renameImage = async (image: PttFile) => {
    if (!window.confirm("Do you want to rename the file?")) return;

    const newName = window.prompt("Enter new name", image.originalName);

    if (!newName) return;

    try {
      await fetch(`${process.env.REACT_APP_API_URL}media/rename`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ _id: image._id, name: newName }),
      });
      alert("The file was renamed. Refresh the page to see the changes.");
    } catch (e) {
      console.error("Cannot rename image", e);
    }
  };

  const getData = async () => {
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_API_URL}media/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({ type, page, itemsPerPage, sortBy }),
    });

    setLoading(false);

    if (res.status === 403) {
      logout();
      navigate("/login");
    } else if (res.status === 200) {
      try {
        const responseData = await res.json();
        setData(responseData);
      } catch (e) {
        console.error("Cannot list media", e);
      }
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy]);

  const PreviewSidebar = () => {
    const imageLoaded = useImageLoaded(previewFile?.previewUrl);
    if (!previewFile) return null;

    return (
      <>
        {type === "image" && (
          <div
            className="mb-2 bg-center bg-no-repeat bg-contain border border-grayLight"
            style={{
              paddingBottom: "100%",
              backgroundImage: imageLoaded
                ? `url(${previewFile?.previewUrl})`
                : ``,
            }}
          ></div>
        )}
        <div className="w-full overflow-hidden text-xs break-words">
          <span style={{ overflowWrap: "anywhere" }}>
            {previewFile ? previewFile.originalName : null}
          </span>
        </div>
        <div className="mt-2">
          <div className="flex">
            <button
              onClick={() => {
                if (previewFile) onChange(previewFile);
              }}
              className="flex-1 p-2 px-4 mr-2 text-sm text-white bg-primary"
            >
              Use
            </button>
            <a
              className="flex items-center justify-center px-4 ml-2 border border-primary"
              target="_blank"
              href={`${previewFile.baseUrl}${previewFile.pathTemplate.path
                .replace("{name}", previewFile.name)
                .replace("{extension}", previewFile.extension)
                .replace("{size}", "0")
                .replace("{quality}", `${previewFile.quality}`)}`}
              rel="noreferrer"
            >
              <span>View</span>
            </a>
          </div>
          <div className="flex justify-between pt-2 mt-4 border-t border-grayLight">
            <button
              className="text-gray hover:underline"
              onClick={() => renameImage(previewFile)}
            >
              Rename
            </button>
            <button
              className="text-gray hover:underline"
              onClick={() => deleteImage(previewFile)}
            >
              Delete
            </button>
          </div>
        </div>
      </>
    );
  };

  const ImagePreview = ({ image }: { image: PttFile }) => {
    const imageLoaded = useImageLoaded(image.previewUrl);

    return (
      <div
        className="relative w-full border bg-grayLight border-grayLight"
        style={{ paddingBottom: "100%" }}
      >
        <div
          onClick={() => {
            if (image) {
              setPreviewFile(image);
            }
          }}
          className={`absolute inset-0 bg-center bg-no-repeat bg-cover ${
            image ? "cursor-pointer" : ""
          }`}
          style={
            imageLoaded ? { backgroundImage: `url(${image.previewUrl})` } : {}
          }
        >
          {image.extension && (
            <div
              className="absolute bottom-0 right-0 px-1 m-1 text-center text-white text-xxs"
              style={{ background: "rgba(0, 0, 0, 0.6)" }}
            >
              {image.extension}
            </div>
          )}
        </div>
      </div>
    );
  };

  const FilePreview = ({ file }: { file: PttFile }) => {
    return (
      <div
        className={`border col-span-1 md:col-span-2 lg:col-span-2 ${
          previewFile?._id === file?._id ? " border-gray" : " border-grayLight"
        }`}
      >
        <div>
          {file ? (
            <div
              onClick={() => {
                if (file) {
                  setPreviewFile(file);
                }
              }}
              className={`block p-2 cursor-pointer flex text-xs`}
              title="Preview"
            >
              <span className="block overflow-hidden flex-3">
                {file ? file.originalName : null}
              </span>
              <span className="flex-1 text-right">{file.type}</span>
              <span className="flex-1 text-right">
                {dayjs(file.createdAt).format("DD/MM/YYYY HH:mm")}
              </span>
            </div>
          ) : (
            <div className="block p-2 bg-grayLight">&nbsp;</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col p-8 bg-white">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center">
          <h2 className="text-xl">{type === "image" ? "Images" : "Files"}</h2>
          <button
            onClick={() => (imagesToUpload === 0 ? selectImages() : null)}
            className={`ml-4 rounded px-2 py bg-primary text-white text-sm ${
              imagesToUpload > 0 ? "opacity-50" : ""
            }`}
          >
            Upload
          </button>
          {imagesToUpload > 0 && (
            <span className="ml-4">
              Uploading files. {imagesToUpload} pending...
            </span>
          )}
        </div>
        <div className="flex">
          <div className="mr-8">
            Sort by:
            {Object.values(SortBy).map((sortByValue) => (
              <button
                onClick={() => setSortBy(sortByValue)}
                className={`ml-2 hover:underline ${
                  sortByValue === sortBy ? "font-medium" : ""
                }`}
              >
                {sortByLabels[sortByValue]}
              </button>
            ))}
          </div>
          <span className="cursor-pointer" onClick={onClose}>
            Close
          </span>
        </div>
      </div>
      {loading && <div className="mb-8">Loading...</div>}
      <div className="flex flex-col-reverse flex-1 overflow-scroll md:flex-row md:items-stretch">
        <div className="md:flex-3 shrink-0">
          <div
            className={`grid grid-cols-2 gap-1 ${
              type === "image" ? "md:grid-cols-4 lg:grid-cols-8" : ""
            }`}
          >
            {!loading &&
              data &&
              data.items.map((image, index) => {
                if (type === "image") {
                  return <ImagePreview image={image} key={index} />;
                }
                return <FilePreview file={image} key={index} />;
              })}
          </div>
          <div>
            {!loading &&
              data &&
              data.numberOfPages > 0 &&
              data.items.length > 0 && (
                <div className="flex my-8">
                  {Array.from({ length: data.numberOfPages }).map(
                    (_, index) => (
                      <span
                        key={index}
                        onClick={() => setPage(index)}
                        className={`mr-2 cursor-pointer ${
                          index === page ? "underline" : ""
                        }`}
                      >
                        {index + 1}
                      </span>
                    )
                  )}
                </div>
              )}
          </div>
        </div>
        <div className="mb-4 md:mb-0 md:border-l md:pl-4 md:ml-4 md:flex-1 border-grayLight">
          {previewFile && <PreviewSidebar />}
        </div>
      </div>
    </div>
  );
};

export default MediaViewer;
