import { useState, useContext } from "react";
import Button from "./Button";
import { GlobalContext } from "../contexts/GlobalContext";
import fetchAPI from "../lib/fetchAPI";
import { useNavigate } from "react-router";
import { EditDataContext } from "../contexts/EditDataContext";

type Props = {
  id: string;
  modelName: string;
  url?: { [key: string]: string };
  get?: () => void;
};

const ActionsTab = ({ id, modelName, url }: Props) => {
  const { data } = useContext(EditDataContext);

  const { getLiteral, getToken, logout, appConfig } =
    useContext(GlobalContext);
  const [loading, setLoading] = useState("");
  const navigate = useNavigate();

  const remove = async () => {
    if (!window.confirm("Delete?")) return;

    setLoading("remove");

    const { valid } = await fetchAPI({
      url: "model/delete",
      method: "DELETE",
      body: {
        modelName,
        _id: id,
      },
      token: getToken(),
    });

    if (!valid) logout();

    setLoading("");

    navigate(`/admin/list/${modelName}`);
  };

  const duplicate = async () => {
    if (!window.confirm("Duplicate?")) return;

    setLoading("duplicate");

    const { valid, data } = await fetchAPI({
      url: "model/duplicate",
      body: {
        modelName,
        _id: id,
      },
      token: getToken(),
    });

    if (!valid) logout();

    setLoading("");

    navigate(`/admin/edit/${modelName}/${data.duplicatedId.insertedId}`);
  };

  const preview = async (language: string, cleanCache = false) => {
    if (!url) return;

    if (cleanCache) {
      setLoading(`preview${language}`);
      const { valid } = await fetchAPI({
        url: "model/clean-cache",
        body: {
          modelName,
          _id: id,
        },
        token: getToken(),
      });

      if (!valid) logout();
      setLoading("");
    }

    const publicURL = new URL(`${appConfig?.app?.url}${url[language]}`);
    publicURL.searchParams.set("ptttoken", getToken() || "");
    window.open(publicURL.toString(), "_blank");
  };

  return (
    <div>
      <div className="flex">
        <div className="mr-2">
          <Button
            loading={loading === "delete"}
            theme="danger"
            disabled={data?.stored?.locked === true}
            onClick={() =>
              data?.stored?.locked ? alert(getLiteral("locked_text")) : remove()
            }
          >
            {getLiteral("delete")}
          </Button>
        </div>
        <div className="mr-2">
          <Button
            loading={loading === "duplicate"}
            theme="outline-dark"
            onClick={duplicate}
          >
            {getLiteral("clone")}
          </Button>
        </div>
      </div>
      {url && (
        <div className="mt-8 ">
          <h2 className="text-xl">{getLiteral("urls")}</h2>
          <table className="w-full">
            <tbody>
              {Object.keys(url).map((key) => (
                <tr key={key} className="border-b border-grayLight">
                  <td className="py-2">{url[key]}</td>
                  <td className="flex justify-end py-2 text-right">
                    <div className="mr-2">
                      <Button
                        theme="outline-dark"
                        onClick={() => preview(key, true)}
                        loading={loading === `preview${key}`}
                      >
                        {getLiteral("refresh")}
                      </Button>
                    </div>
                    <div>
                      <Button theme="outline-dark" onClick={() => preview(key)}>
                        {getLiteral("preview")}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ActionsTab;
