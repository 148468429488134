import React, { useContext, useEffect, useState } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import Button from "../Button";
import useLocalStorage from "../../hooks/useLocalStorage";

type pendingEvents = {
  count: number;
  limit: number;
  events: Array<any>;
};

const EventsList = (): React.ReactElement => {
  const tableColumns = ["name", "createdAt", "ip"];
  const [pendingEvents, setPendingEvents] = useState<pendingEvents | null>(null);
  const [token] = useLocalStorage("token", undefined);

  const { storedData } = useContext(EditDataContext);

  useEffect(() => {
    if (!storedData?.title) return;

    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}events/get-app-events?app=${storedData.title}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();
        if (json.error) return
        setPendingEvents(json);
      } catch (err) {
        alert(err);
      }
    })();
  }, [storedData]);

  const handleSendEvents = async () => {
    if (!storedData?.title) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}events/dump-app-events?app=${storedData.title}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ app: storedData.title }),
        }
      );
      const json = await response.json();
      if (json.deleted) {
        alert("Events successfully sent");
        setPendingEvents(null);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      {pendingEvents === null || pendingEvents?.events?.length === 0 ? (
        <div className="text-xs font-light uppercase ">No pending events</div>
      ) : (
        <>
          <span className="text-xs font-light uppercase">
            Pending Events (
            {pendingEvents?.limit > pendingEvents?.count
              ? `${pendingEvents?.count}/${pendingEvents?.count}`
              : `${pendingEvents?.limit}/${pendingEvents?.count}`}
            )
          </span>
          <table className="w-full mt-1 border border-grayLight">
            <thead className="w-full px-2 py-1 mt-1 text-xs text-left bg-white border border-grayLight ">
              <tr>
                {tableColumns.map((col) => (
                  <th className="px-4 py-2" key={col}>
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pendingEvents?.events?.map((event) => (
                <tr
                  className="relative text-xs bg-white border-b border-grayLight hover:bg-primaryLight"
                  key={JSON.stringify(event.data)}
                >
                  {tableColumns.map((col) => (
                    <td className="px-4 py-2" key={col}>
                      {typeof event[col] === "object"
                        ? JSON.stringify(event[col])
                        : event[col]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-2">
            <Button onClick={handleSendEvents} theme="dark">Send events</Button>
          </div>
        </>
      )}
    </>
  );
};

export default EventsList;
