import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Actions from "../components/Actions";
import Button from "../components/Button";
import Container from "../components/Container";
import { GlobalContext } from "../contexts/GlobalContext";
import useLocalStorage from "../hooks/useLocalStorage";
import fetchAPI from "../lib/fetchAPI";
import printValue from "../lib/printValue";

const List = () => {
  const { name: modelName } = useParams();
  const navigate = useNavigate();
  const { appConfig, getToken, logout, getLiteral } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sorting, setSorting] = useLocalStorage(`${modelName}-sorting`, null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [title, setTitle] = useState(modelName);
  const [page, setPage] = useState(searchParams?.get('page') ? Number(searchParams.get('page')) : 0);

  const getData = async () => {
    setLoading(true);

    const body = {
      modelName,
      page,
    };

    body.itemsPerPage = appConfig?.app?.itemsPerPage || 50;

    if (sorting) {
      body.sorting = sorting;
    }

    const { valid, data } = await fetchAPI({
      url: "model/list",
      body,
      token: getToken(),
    });

    if (!valid) logout();

    setTitle(data.modelConfig.title);
    Object.keys(data.modelConfig.list).forEach((key) => {
      if (typeof data.modelConfig.list[key] === "object") {
        data.modelConfig.list[key] = data.modelConfig.list[key].label;
      }
    });
    setData(data);

    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelName, sorting, page]);

  useEffect(() => {
    setSearchParams({
      page,
    });
  }, [page, setSearchParams]);

  const handleSort = (key) => {
    if (sorting && sorting.key === key) {
      if (sorting.direction === "asc") {
        setSorting(null);
      } else {
        setSorting({ key, direction: "asc" });
      }
    } else {
      setSorting({ key, direction: "desc" });
    }
  };

  const goToDetail = (id) => {
    navigate(`/admin/edit/${modelName}/${id}`, {
      state: { page },
    });
  };

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Container>
          <div className="flex items-center justify-between mb-8">
            <h1 className="font-medium text-xxl">{title}</h1>
            <Button
              to={{
                pathname: `/admin/edit/${modelName}`,
              }}
              theme={"dark"}
            >
              Add
            </Button>
          </div>
          {loading && <div className="text-gray">Loading...</div>}
          {!loading && data && data.items.length === 0 && (
            <div>Nothing to list</div>
          )}
          {!loading && data && data.items.length > 0 && (
            <table className="w-full mb-8">
              <thead className="w-full text-left bg-white border-b border-grayLight">
                <tr>
                  {Object.keys(data.modelConfig.list).map(
                    (key) =>
                      key !== "_id" && (
                        <th key={key} className="p-2">
                          <span
                            className="relative text-xs font-normal uppercase cursor-pointer"
                            onClick={() => handleSort(key)}
                          >
                            {data.modelConfig.list[key]}
                            {sorting && sorting.key === key && (
                              <span className="text-xs font-normal l-2">
                                {sorting.direction === "asc" ? "↑" : "↓"}
                              </span>
                            )}
                          </span>
                        </th>
                      )
                  )}
                  <th
                    align="right"
                    className="py-2 text-xs font-normal uppercase"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {data.items.map((item) => (
                  <tr
                    key={item._id}
                    className="relative bg-white border-b border-grayLight hover:bg-grayLighter"
                  >
                    {Object.keys(data.modelConfig.list).map(
                      (key) =>
                        !["_id", "publication"].includes(key) && (
                          <td
                            key={key}
                            className="p-2 py-3 cursor-pointer"
                            onClick={() => goToDetail(item._id)}
                          >
                            {typeof item[key] !== "undefined" &&
                              printValue({ item, key })}
                          </td>
                        )
                    )}
                    <td align="right" className="p-2 py-3 text-right">
                      <div className="flex items-center justify-end">
                        {data?.modelConfig?.hasPublication && (
                          <div
                            className={`mr-4 px-2 rounded bg-${item?.publication?.status}Soft inline-flex items-center text-xs`}
                          >
                            <div
                              className={`mr-2 w-2 h-2 rounded-full bg-${item?.publication?.status}`}
                            ></div>
                            <span>{getLiteral(item?.publication?.status)}</span>
                          </div>
                        )}
                        <div className="flex justify-end">
                          <Actions
                            page={page}
                            modelName={modelName}
                            id={item._id}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {!loading && data.numberOfPages > 1 && data.items.length > 0 && (
            <div className="flex justify-end my-8 text-sm text-gray">
              <div className="flex">
                {page - 3 > 0 && <span className="mr-2">...</span>}
                {Array.from({ length: data.numberOfPages }).map((_, index) =>
                  Math.abs(index - page) < 5 ? (
                    <span
                      key={index}
                      onClick={() => setPage(index)}
                      className={`mr-2 cursor-pointer ${
                        index === page ? "text-black" : ""
                      }`}
                    >
                      {index + 1}
                    </span>
                  ) : null
                )}
                {page + 3 < data.numberOfPages && <span>...</span>}
              </div>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default List;
