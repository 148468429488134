import React, { useContext, useEffect, useState } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import { PttModelField } from "../../types";
import Field from "./Field";
import { Link, useSearchParams } from "react-router-dom";
interface InstallationsType extends PttModelField {
  options: {
    label: string;
    model?: string;
    options: { [key: string]: string };
  };
}

type InstallationsSelectType = {
  app: string;
  installations: [InstallationSelectType];
};

type InstallationSelectType = {
  _id: string;
  title: string;
};

const Installations = ({ field }: { field: InstallationsType }) => {
  const { updateStored, getValue, getLanguage, data } =
    useContext(EditDataContext);
  const language = getLanguage({ field });
  const value = getValue({
    field,
    defaultValue: [],
  });
  const [options, setOptions] = useState<InstallationsSelectType[]>([]);

  const [searchParams] = useSearchParams();
  const predefinedValue = searchParams.get(field.name);
  useEffect(() => {
    if (predefinedValue) {
      value[language] = predefinedValue;
      updateStored(field, value);
    }
  }, [predefinedValue]);

  useEffect(() => {
    if (
      data?.find &&
      field?.options &&
      field?.options?.model &&
      data?.find[field?.options?.model]
    ) {
      const items = data.find[field?.options?.model] as any[];
      const newOptions: InstallationsSelectType[] = [];
      for (const item of items) {
        const existingAppIndex = newOptions.findIndex(
          (option) => option.app === (item?.rawItem?.app as string)
        );
        if (existingAppIndex > -1) {
          newOptions[existingAppIndex].installations.push({
            _id: item._id,
            title: item.title,
          });
        } else {
          newOptions.push({
            app: item?.rawItem?.app,
            installations: [
              {
                _id: item._id,
                title: item.title,
              },
            ],
          });
        }
      }
      setOptions(newOptions);
    }
  }, [field]);

  const handleIds = (add: boolean, ids: string[]) => {
    const valueItems = value[language] as string[];
    if (add) {
      for (const id of ids) {
        if (!valueItems.includes(id)) {
          valueItems.push(id);
        }
      }
    } else {
      for (const id of ids) {
        if (valueItems.includes(id)) {
          valueItems.splice(valueItems.indexOf(id), 1);
        }
      }
    }
    updateStored(field, value);
  };

  const AppOption = ({ app, installations }: InstallationsSelectType) => {
    const valueItems = value[language] as string[];
    const isAppSelected = installations.every((installation) =>
      valueItems.includes(installation._id)
    );

    return (
      <div className="mt-4 text-xs bg-white border border-grayLight">
        <div className="flex justify-between p-2 border-b border-grayLight">
          <div className="font-medium ">{app}</div>
          <div>
            <span className="mr-2">select all</span>
            <input
              checked={isAppSelected}
              type="checkbox"
              onChange={(e) =>
                handleIds(
                  e.target.checked,
                  installations.map((i) => i._id)
                )
              }
            />
          </div>
        </div>
        {installations.map((installation) => (
          <div
            key={installation._id}
            className="flex justify-between px-2 py-1"
          >
            <div>
              <input
                checked={valueItems.includes(installation._id)}
                onChange={(e) =>
                  handleIds(e.target.checked, [installation._id])
                }
                type="checkbox"
              />
              <span className="ml-2">{installation.title}</span>
            </div>
            <Link
              target="_blank"
              to={`/admin/edit/Installation/${installation._id}`}
            >
              View
            </Link>
          </div>
        ))}
      </div>
    );
  };

  const valueItems = value[language] as string[];
  const isAllSelected = options.every(({ installations }) => {
    return installations.every((installation) =>
      valueItems.includes(installation._id)
    );
  });

  return (
    <Field field={field}>
      <div className="flex justify-end">
        <span className="mr-2">All installations</span>
        <input
          type="checkbox"
          checked={isAllSelected}
          onChange={(e) => {
            handleIds(
              e.target.checked,
              options.flatMap(({ installations }) =>
                installations.map((i) => i._id)
              )
            );
          }}
        />
      </div>
      <div>
        {options &&
          options.length > 0 &&
          options.map((option) => <AppOption key={option.app} {...option} />)}
      </div>
    </Field>
  );
};

export default Installations;
