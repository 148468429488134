/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { GlobalContext } from '../contexts/GlobalContext'
import fetchAPI from '../lib/fetchAPI'
import useDebounce from '../hooks/useDebounce'
import { Rule } from './BasicRulesTab'
import { StoredDataType } from '../types'

interface TabItemProps {
  label: string
  isActive: boolean
  onClick: () => void
}

interface DropdownProps {
  label: string
  items: ProductsType[]
  onDelete: (item: string) => void
  onClearAll: () => void
}

interface SearchBarProps {
  searchTerm: string
  setSearchTerm: (term: string) => void
  loading: boolean
  products: ProductsType[]
  handleSelect: (product: ProductsType) => void
  label: string
  type: string
  includedItems: ProductsType[]
  excludedItems: ProductsType[]
}

interface ProductsType {
  id: string | number
  value: string
}

const TabItem: React.FC<TabItemProps> = ({ label, isActive, onClick }) => {
  return (
    <li
      className={`cursor-pointer px-4 py-2 border ${
        isActive
          ? 'bg-white text-primary border-primary'
          : 'bg-gray-100  border-grayLight'
      } flex items-center justify-center`}
      onClick={onClick}
    >
      {isActive && <span className='mr-2'>✔️</span>}
      {label}
    </li>
  )
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  items,
  onDelete,
  onClearAll,
}) => {
  const [show, setShow] = useState(false)

  return (
    <div className='w-full flex flex-col'>
      <header className='w-full flex justify-between items-center'>
        <div
          className='uppercase  cursor-pointer'
          onClick={() => setShow(!show)}
        >
          {!show ? '▲' : '▼'} {label} ({items.length})
        </div>
        {items.length > 0 && (
          <button onClick={onClearAll} className='underline text-sm'>
            Clear all
          </button>
        )}
      </header>

      {show && (
        <div className='flex flex-wrap mt-2 border border-grayLight p-2 gap-2 bg-white'>
          {items.length === 0 && (
            <div className='w-full h-12 flex items-center justify-center'>
              No items
            </div>
          )}
          {items.map(item => (
            <div
              key={item.id}
              className='flex items-center bg-white px-2 py-1 rounded border'
            >
              <span>{item.value}</span>
              <button
                className='ml-2 text-gray'
                onClick={() => onDelete(item.id.toString())}
              >
                ✕
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  setSearchTerm,
  loading,
  products,
  handleSelect,
  label,
  type,
  includedItems,
  excludedItems,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false)
        setSearchTerm('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setSearchTerm])

  return (
    <div className='relative w-full' ref={dropdownRef}>
      <div className='flex items-center border border-grayLight bg-white'>
        <input
          ref={inputRef}
          type='text'
          placeholder={`Search for ${label}...`}
          className='w-full px-4 py-2 outline-none'
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value)
            setShowDropdown(true)
          }}
          onFocus={() => setShowDropdown(true)}
        />
        {searchTerm && (
          <button
            className='mr-2 text-gray-500'
            onClick={() => {
              setSearchTerm('')
              setShowDropdown(false)
            }}
          >
            ✕
          </button>
        )}
      </div>
      {showDropdown && searchTerm && (
        <div className='absolute w-full border border-gray-200 bg-white mt-1 max-h-60 overflow-y-auto z-10'>
          {loading ? (
            <div className='w-full h-32 flex items-center justify-center'>
              Loading...
            </div>
          ) : products.length === 0 ? (
            <div className='w-full h-32 flex items-center justify-center'>
              No results
            </div>
          ) : (
            products.map((product: ProductsType, index: number) => (
              <div
                key={index}
                className={`flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer`}
              >
                <input
                  type='checkbox'
                  className='mr-2 '
                  onChange={() => handleSelect(product)}
                  checked={
                    type === 'included'
                      ? includedItems
                          .map(p => p.id)
                          .includes(product.id.toString())
                      : excludedItems
                          .map(p => p.id)
                          .includes(product.id.toString())
                  }
                  disabled={
                    includedItems
                      .map(p => p.id)
                      .includes(product.id.toString()) ||
                    excludedItems.map(p => p.id).includes(product.id.toString())
                  }
                />
                <span className='flex-grow'>{product.value}</span>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  )
}

interface BasicRulesProps {
  title: string
  includedRule: Rule
  excludedRule: Rule
  storedData: StoredDataType
}

export default function BasicRules({
  title,
  includedRule: initialIncludedRule,
  excludedRule: initialExcludedRule,
  storedData,
}: BasicRulesProps) {
  const [tab, setTab] = useState<'included' | 'excluded'>('included')
  const { getToken, logout } = useContext(GlobalContext)
  const [includedProducts, setIncludedProducts] = useState<ProductsType[]>([])
  const [excludedProducts, setExcludedProducts] = useState<ProductsType[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<ProductsType[]>([])
  const [includedRule, setIncludedRule] = useState<Rule>(initialIncludedRule)
  const [excludedRule, setExcludedRule] = useState<Rule>(initialExcludedRule)
  const debounce = useDebounce(searchTerm, 1500)

  useEffect(() => {
    const fetchInitialProductNames = async () => {
      const includedIds = includedRule.row.split(', ').filter(Boolean)
      const excludedIds = excludedRule.row.split(', ').filter(Boolean)
      const allIds = [...includedIds, ...excludedIds]

      if (allIds.length > 0) {
        try {
          const fetchedProducts = await getProductsName(allIds as [])
          setIncludedProducts(
            includedIds.map(id => ({
              id,
              value:
                fetchedProducts.find((p: ProductsType) => p.id === Number(id))
                  ?.value || id,
            }))
          )

          setExcludedProducts(
            excludedIds.map(id => ({
              id,
              value:
                fetchedProducts.find((p: ProductsType) => p.id === Number(id))
                  ?.value || id,
            }))
          )
        } catch (error) {
          console.error('Error fetching initial product names:', error)
        }
      } else {
        setIncludedProducts(includedIds.map(id => ({ id, value: id })))
        setExcludedProducts(excludedIds.map(id => ({ id, value: id })))
      }
    }

    fetchInitialProductNames().catch(error =>
      console.error('Error fetching initial product names:', error)
    )
  }, [includedRule, excludedRule])

  const getProductsName = async (ids: Number[]) => {
    setLoading(true)

    const body = {
      ids: Array.from(new Set(ids)),
      type: title,
    }

    try {
      const { valid, data } = await fetchAPI({
        url: 'products/getProductsNames',
        body,
        token: getToken(),
      })

      if (!valid) {
        return
      }

      return data
    } catch (error) {
      console.error('Error fetching product names:', error)
      return
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!searchTerm) {
      return
    }
    getProducts()
  }, [debounce])

  const getProducts = async () => {
    if (!searchTerm) {
      return
    }

    setLoading(true)

    const body: any = {
      tab: tab,
      type: title,
      searchTerm,
    }

    try {
      const { valid, data } = await fetchAPI({
        url: 'products/searchProducts',
        body,
        token: getToken(),
      })

      if (!valid) {
        logout()
        return
      }

      setProducts(data)
    } catch (error) {
      console.error(error)
      logout()
    } finally {
      setLoading(false)
    }
  }

  const updateRuleRow = (products: ProductsType[], rule: Rule) => {
    return {
      ...rule,
      row: products.map(p => p.id.toString()).join(', '),
    }
  }

  const updateOrAddRule = (rule: Rule, type: string, data: StoredDataType) => {
    const types: Record<string, string> = {
      included: 'includedRules',
      exclude: 'excludedRules',
    }
    const ruleType = types[type]

    if (ruleType) {
      let rules: Rule[] = []
      rules = data[ruleType] as Rule[]

      if (rules && rules.length > 0) {
        const index = rules.findIndex(r => r.key === rule.key)
        if (index !== -1) {
          rules[index] = rule
        } else {
          rules.push(rule)
        }
      } else {
        rules = [rule]
      }
      data[ruleType] = rules
    }
  }

  const handleSelect = (product: ProductsType) => {
    if (tab === 'included') {
      if (!includedProducts.some(p => p.id === product.id)) {
        const newIncludedProducts = [...includedProducts, product]
        setIncludedProducts(newIncludedProducts)
        setIncludedRule(updateRuleRow(newIncludedProducts, includedRule))
        updateOrAddRule(
          updateRuleRow(newIncludedProducts, includedRule),
          'included',
          storedData
        )
      }
    } else if (tab === 'excluded') {
      if (!excludedProducts.some(p => p.id === product.id)) {
        const newExcludedProducts = [...excludedProducts, product]
        setExcludedProducts(newExcludedProducts)
        setExcludedRule(updateRuleRow(newExcludedProducts, excludedRule))
        updateOrAddRule(
          updateRuleRow(newExcludedProducts, excludedRule),
          'exclude',
          storedData
        )
      }
    }
  }

  const handleDeleteIncluded = (product: ProductsType) => {
    const newIncludedProducts = includedProducts.filter(
      p => p.id !== product.id
    )
    setIncludedProducts(newIncludedProducts)
    setIncludedRule(updateRuleRow(newIncludedProducts, includedRule))
    updateOrAddRule(
      updateRuleRow(newIncludedProducts, includedRule),
      'included',
      storedData
    )
  }

  const handleDeleteExcluded = (product: ProductsType) => {
    const newExcludedProducts = excludedProducts.filter(
      p => p.id !== product.id
    )
    setExcludedProducts(newExcludedProducts)
    setExcludedRule(updateRuleRow(newExcludedProducts, excludedRule))
    updateOrAddRule(
      updateRuleRow(newExcludedProducts, excludedRule),
      'exclude',
      storedData
    )
  }

  const handleClearAll = (type: string) => {
    if (type === 'included') {
      setIncludedProducts([])
      setIncludedRule(updateRuleRow([], includedRule))
      updateOrAddRule(updateRuleRow([], includedRule), 'included', storedData)
    } else {
      setExcludedProducts([])
      setExcludedRule(updateRuleRow([], excludedRule))
      updateOrAddRule(updateRuleRow([], excludedRule), 'exclude', storedData)
    }
  }

  return (
    <section className='flex flex-col gap-2 p-4 w-full'>
      <header className='border-b w-full mb-4'>
        <h1 className='text-base'>{title}</h1>
      </header>

      <main className='w-full flex flex-col gap-2'>
        <header className='w-full flex flex-row'>
          <ul className='flex space-x-2'>
            <TabItem
              label='Included selection'
              isActive={tab === 'included'}
              onClick={() => setTab('included')}
            />
            <TabItem
              label='Excluded selection'
              isActive={tab === 'excluded'}
              onClick={() => setTab('excluded')}
            />
          </ul>
        </header>

        <div className='relative w-1/2'>
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            loading={loading}
            products={products}
            handleSelect={handleSelect}
            label={title}
            type={tab}
            includedItems={includedProducts}
            excludedItems={excludedProducts}
          />
        </div>

        <section className='flex flex-col gap-4 mt-10'>
          <Dropdown
            label='Included'
            items={includedProducts}
            onDelete={id =>
              handleDeleteIncluded(includedProducts.find(p => p.id === id)!)
            }
            onClearAll={() => handleClearAll('included')}
          />
          <Dropdown
            label='Excluded'
            items={excludedProducts}
            onDelete={id =>
              handleDeleteExcluded(excludedProducts.find(p => p.id === id)!)
            }
            onClearAll={() => handleClearAll('excluded')}
          />
        </section>
      </main>
    </section>
  )
}
