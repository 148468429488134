import { useState, Dispatch, useContext } from 'react'
import Modal from './Modal'
import ModalRules from './ModalRules'
import Button from './Button'
import fetchAPI from '../lib/fetchAPI'
import { GlobalContext } from '../contexts/GlobalContext'

const ModalPendingEventFix = ({
  apps,
  installations,
  clients,
  selectedId,
  setShowModal,
  refresh,
  tablesList,
}: {
  apps: any[]
  installations: any[]
  clients: any[]
  selectedId: any
  setShowModal: Dispatch<boolean>
  refresh: any
  tablesList: string[]
}) => {
  const { getToken } = useContext(GlobalContext)
  const [searchValue, setSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState<any[]>([])
  const [selectedItem, setSelectedItem] = useState<any>()
  const [customerId, setCustomerId] = useState('')
  const [includedRules, setIncludedRules] = useState<any[]>([])
  const [excludedRules, setExcludedRules] = useState<any[]>([])

  const search = (type: string, value: string) => {
    if (selectedItem) setSelectedItem(undefined)
    setSearchValue(value)

    const values: { [key: string]: any[] } = {
      app: apps,
      installation: installations,
    }

    if (!value && type === 'installation') {
      setSearchResult(values[type].filter(i => i && (i.appId === selectedId.app)))
      return
    }

    const idsProps: { [key: string]: string } = {
      app: 'appId',
      installation: 'installationId',
    }
    const regex = new RegExp(value, 'i')
    const result = values[type].filter(
      (i) => {
        if (type === 'installation' && i?.appId !== selectedId.app) return false
        return i && (regex.test(i.title) || regex.test(i[idsProps[type]]))
      }
    )
    setSearchResult(result)
  }

  const [selectedClient, setSelectedClient] = useState<any>()
  const [searchClientResult, setSearchClientResult] = useState<any[]>([])
  const [searchClientValue, setSearchClientValue] = useState('')

  const searchClient = (value: string) => {
    if (selectedClient) setSelectedClient(undefined)
    setSearchClientValue(value)
    if (!value) return setSearchClientResult([])
    const regex = new RegExp(value, 'i')
    const result = clients.filter(
      (i) => i && (regex.test(i.title) || regex.test(i.clientId))
    )
    setSearchClientResult(result)
  }

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [newItemTitle, setNewItemTitle] = useState('')

  const handleCreate = async () => {
    setError('')
    const data: any = {}

    if (selectedId.type === 'app') {
      if (!selectedClient) {
        setError('Please select a client')
        return
      }
      if (!newItemTitle) {
        setError('Please enter a title')
        return
      }
      data.title = newItemTitle
      data.clientId = selectedClient._id
      data.appId = selectedId.id
      if (!data.appId) {
        setError('appId is not valid')
        return
      }
    }

    if (selectedId.type === 'installation') {
      if (!newItemTitle) {
        setError('Please enter a title')
        return
      }
      data.app = apps.find((app) => app.appId === selectedId.app)?._id
      if (!data.app) {
        setError('Please fix the app first')
        return
      }
      data.title = newItemTitle
      data.installationId = selectedId.id || 'default'
      data.customerId = customerId
      data.includedRules = includedRules
      data.excludedRules = excludedRules
    }

    setLoading(true)
    const res = await fetchAPI({
      token: getToken(),
      url: 'events/fix-pending-events ',
      body: {
        action: 'create',
        data,
        type: selectedId.type,
      },
    })
    setLoading(false)
    if (res?.valid) {
      refresh(true)
      setShowModal(false)
    }
  }

  const handleUpdate = async () => {
    setError('')
    if (!selectedItem) {
      setError(`Please select an ${selectedId.type}`)
      return
    }

    if (window?.confirm(
      `Are you sure you want to update the ${selectedId.type} "${selectedItem.title}" with ${selectedId.type}Id "${selectedItem.installationId || selectedItem.appId}" to "${selectedId.id || 'default'}"?`
    )) {
      setLoading(true)
      const res = await fetchAPI({
        token: getToken(),
        url: 'events/fix-pending-events ',
        body: {
          action: 'update',
          data: {
            id: selectedItem.installationId || selectedItem.appId,
            newId: selectedId.id || 'default',
          },
          type: selectedId.type,
        },
      })
      setLoading(false)
      if (res?.valid) {
        refresh(true)
        setShowModal(false)
      }
    }
  }

  return (
    <Modal size={'w-4/5 md:w-3/5'} setShowModal={setShowModal}>
      <div className="z-40 mt-4">
        <h3 className="text-xl ">Fix missing {selectedId.type}</h3>
        <p className="mb-4 ">
          We have not found the {selectedId.type} with ID <strong>{selectedId.id || 'default'}</strong>. You can create a new one or search an existing {selectedId.type}{selectedId.type === 'installation' && ` of the "${selectedId.app}" app `} and update its <strong>{selectedId.type}Id</strong> with <strong>{selectedId.id || 'default'}</strong>.
        </p>
        <div className="py-2 my-2">
          <p className="font-medium">Search for {selectedId.type}:</p>
          <p className="mb-2 text-xs">{selectedId.type === 'installation' && `Only installations of app "${selectedId.app}" will be shown. `}This will overwrite the selected {selectedId.type}Id to <strong>{selectedId.id || 'default'}</strong>.</p>
          <div className="flex mb-2 text-xs">
            <div className="relative mb-2 mr-4">
              <input
                type="text"
                placeholder={selectedId.type}
                className="px-2 py-1 mr-2 placeholder-opacity-50 bg-white border outline-none w-80 placeholder-gray border-grayLight"
                onChange={(e) => search(selectedId.type, e.target.value)}
                onFocus={() => search(selectedId.type, '')}
                onBlur={() => setTimeout(() => setSearchResult([]), 200)}
                value={
                  searchValue ||
                  ( selectedItem?.installationId ? `${selectedItem?.title} (${selectedItem?.installationId})` : '') ||
                  ( selectedItem?.appId ? `${selectedItem?.title} (${selectedItem?.appId})` : '')
                }
              />
              {searchResult?.length ? (
                <div className="absolute z-20 overflow-scroll bg-white border w-80 max-h-40 border-grayLight">
                  {searchResult.map((i) => (
                    <span
                      key={i.id}
                      className="block p-1 cursor-pointer hover:bg-grayLight "
                      onClick={() => {
                        setSelectedItem(i)
                        setSearchValue('')
                      }}
                    >
                      {i.title}
                      {i.installationId ? ` (${i.installationId}) - ${i.appId}` : ''}
                      {!i.installationId && i.appId ? ` (${i.appId})` : ''}
                    </span>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {selectedItem && (
                <Button theme="danger" onClick={handleUpdate}>
                  Update this {selectedId.type} ID for{' '}
                  <strong>{selectedId.id || 'default'}</strong>
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="pb-2 mt-2 border-grayLight">
          <p className="mb-2 font-medium">Create a new {selectedId.type}:</p>
          <div className="flex mb-2 text-xs">
            <div className="mb-2 mr-4">
              <label className="block text-xs uppercase text-gray">
                {selectedId.type} title
              </label>
              <input
                type="text"
                placeholder={`Title`}
                className="px-2 py-1 placeholder-opacity-50 bg-white border outline-none placeholder-gray border-grayLight"
                onChange={(e) => setNewItemTitle(e.target.value)}
              />
            </div>
            <div className="mb-2 mr-4">
              <label className="block text-xs uppercase text-gray">
                {selectedId.type} ID
              </label>
              <input
                type="text"
                placeholder={`${selectedId.type}Id`}
                value={selectedId.id || 'default'}
                disabled
                className="px-2 py-1 placeholder-opacity-50 border outline-none bg-grayLight placeholder-gray border-grayLight"
              />
            </div>
            <div className="mb-2 mr-4">
              <label className="block text-xs uppercase text-gray">
                Related {selectedId.type === 'installation' ? 'App' : 'Client'}
              </label>
              {selectedId.app ? (
                <input
                  type="text"
                  placeholder={`App ID`}
                  value={selectedId.app}
                  disabled
                  className="px-2 py-1 placeholder-opacity-50 border outline-none bg-grayLight placeholder-gray border-grayLight"
                />
              ) : (
                <div>
                  <input
                    type="text"
                    placeholder="Client"
                    className="px-2 py-1 mr-2 placeholder-opacity-50 bg-white border outline-none w-80 placeholder-gray border-grayLight"
                    onChange={(e) => searchClient(e.target.value)}
                    onBlur={() =>
                      setTimeout(() => setSearchClientResult([]), 200)
                    }
                    value={searchClientValue || selectedClient?.title}
                  />
                  {searchClientResult?.length ? (
                    <div className="absolute overflow-scroll bg-white border w-80 max-h-40 border-grayLight">
                      {searchClientResult.map((i) => (
                        <span
                          key={i._id}
                          className="block p-1 cursor-pointer hover:bg-grayLight "
                          onClick={() => {
                            setError('')
                            setSelectedClient(i)
                            setSearchClientValue('')
                          }}
                        >
                          {i.title}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
          {selectedId.type === 'installation' && (
            <div className="p-2 mb-2 text-xs border border-grayLight">
              <p className="mb-1">
                (Optional) You can assign some extra values to the new
                installation:
              </p>
              <div className="mb-2">
                <label className="block text-gray">CustomerId</label>
                <input
                  type="text"
                  placeholder="Customer ID"
                  className="px-2 py-1 placeholder-opacity-50 bg-white border outline-none placeholder-gray border-grayLight"
                  onChange={(e) => setCustomerId(e.target.value)}
                />
              </div>
              <ModalRules field={{
                name: "includedRules",
                title: "Included rules",
                value: includedRules,
                setValue: setIncludedRules,
              }} extra={{ tables: tablesList }} />
              <ModalRules field={{
                name: "excludedRules",
                title: "Excluded rules",
                value: excludedRules,
                setValue: setExcludedRules,
              }} extra={{ tables: tablesList }} />
            </div>
          )}
          <div>
            <div>
              <Button
                theme="dark"
                onClick={handleCreate}
                loading={loading}
              >
                Create
              </Button>
            </div>
            {error && <span className="text-xs text-error">{error}</span>}
          </div>
        </div>
        <div className='flex justify-end w-full'>
          <Button
            theme="danger"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalPendingEventFix
